'use client';
import { LatLng, Map as Leaflet } from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CircleMarker, MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useRouter, useSearchParams } from 'next/navigation';
import { Place } from './page';
import cn from 'mxcn';
import { upperFirst } from 'lodash';
import { ChevronsUpDown } from 'lucide-react';
import {
  defaultLatitude,
  defaultLongitude,
  defaultRadius,
  defaultSearch,
  defaultZoom,
  METERS_PER_MILE,
  placeTypes,
} from './constants';

function pixelsPerMeter(latitude: number, zoom: number) {
  const earthCircumference = 40_075_016.686;
  return Math.abs(
    1 / ((earthCircumference * Math.cos(latitude)) / Math.pow(2, zoom + 8))
  );
}

export default function Map(props: { results: Place[] }) {
  const seen = useRef({} as Record<string, Place>);
  for (const place of props.results) {
    seen.current[place.id] = place;
  }

  const searchParams = useSearchParams();
  const urlValues = {
    latitude: Number(searchParams.get('latitude') ?? defaultLatitude),
    longitude: Number(searchParams.get('longitude') ?? defaultLongitude),
    zoom: Number(searchParams.get('zoom') ?? defaultZoom),
    radius: Number(searchParams.get('radius') ?? defaultRadius),
    search: searchParams.get('search') ?? defaultSearch,
  };
  const [search, setSearch] = useState(urlValues.search);
  const [center, setCenter] = useState(
    new LatLng(urlValues.latitude, urlValues.longitude)
  );
  const [zoom, setZoom] = useState(urlValues.zoom);
  const [radius, setRadius] = useState(urlValues.radius);

  const changed =
    urlValues.latitude !== center.lat ||
    urlValues.longitude !== center.lng ||
    urlValues.zoom !== zoom ||
    urlValues.radius !== radius ||
    urlValues.search !== search;

  const [map, setMap] = useState<Leaflet | null>(null);
  const [searchOnMove, setSearchOnMove] = useState(false);
  const router = useRouter();

  const updateUrl = useCallback(
    (values: Partial<typeof urlValues> = {}) => {
      const params = new URLSearchParams();
      params.set(
        'latitude',
        values.latitude?.toString() ?? center.lat.toString()
      );
      params.set(
        'longitude',
        values.longitude?.toString() ?? center.lng.toString()
      );
      params.set('zoom', values.zoom?.toString() ?? zoom.toString());
      params.set('radius', values.radius?.toString() ?? radius.toString());
      params.set('search', values.search ?? search);
      router.push('/?' + params.toString());
    },
    [center.lat, center.lng, radius, router, search, zoom]
  );

  const download = useCallback(() => {
    let csvText = 'Name,Phone,City,Website\n';
    for (const place of Object.values(seen.current)) {
      csvText +=
        '"' +
        [
          place.displayName.text,
          place.nationalPhoneNumber,
          place.addressComponents.find((c) => c.types.includes('locality'))
            ?.longText ?? '',
          place.websiteUri ?? '',
        ].join('","') +
        '"\n';
    }
    const blob = new Blob([csvText], {
      type: 'application/csv',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    const date = new Date();
    a.download = `Leads ${date.toISOString()}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  }, []);

  useEffect(() => {
    if (map) {
      const onMove = () => setCenter(map.getCenter());
      const onMoveEnd = () => {
        if (searchOnMove) {
          updateUrl();
        }
      };
      const onZoom = () => setZoom(map.getZoom());
      map.on('move', onMove);
      map.on('moveend', onMoveEnd);
      map.on('zoom', onZoom);
      return () => {
        map.off('move', onMove);
        map.off('moveend', onMoveEnd);
        map.off('zoom', onZoom);
      };
    }
  }, [map, searchOnMove, updateUrl]);

  return (
    <div className="relative flex flex-auto bg-contrast-100 overflow-hidden h-screen w-full">
      <MapContainer
        center={center}
        ref={(ref) => setMap(ref)}
        zoom={zoom}
        scrollWheelZoom
        className="h-screen flex-auto w-full"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <CircleMarker
          center={center}
          pathOptions={{ color: 'red' }}
          radius={pixelsPerMeter(center.lat, zoom) * (radius * METERS_PER_MILE)}
        />
        {Object.values(seen.current).map((place) => (
          <Marker
            key={place.id}
            position={{
              lat: place.location.latitude,
              lng: place.location.longitude,
            }}
          ></Marker>
        ))}
      </MapContainer>
      <div className="absolute row justify-between top-4 left-16 right-4 z-[500]">
        <button
          className={cn(
            searchOnMove ? 'text-gray-800' : 'text-gray-400',
            'rounded-full bg-white px-6 py-4 leading-none cursor-pointer transition hover:bg-gray-100 shadow-xl font-bold'
          )}
          onClick={() => setSearchOnMove(!searchOnMove)}
        >
          {searchOnMove ? '✓ ' : ' '}
          Search as I move
        </button>

        {changed && (
          <button
            className="rounded-full text-white bg-blue-500 shadow-xl py-4 px-6 leading-none hover:bg-blue-400 font-bold transition"
            onClick={() => {
              updateUrl();
            }}
          >
            Search this area
          </button>
        )}
        <div className="row text-white">
          <button
            onClick={() => setRadius(0.5)}
            className="rounded-l-full bg-gray-500 transition cursor-pointer hover:bg-gray-400 font-bold leading-none pl-6 pr-4 py-4"
          >
            1/2 mile
          </button>
          <button
            onClick={() => setRadius(1)}
            className="bg-gray-500 transition cursor-pointer hover:bg-gray-400 font-bold leading-none pl-6 pr-4 py-4"
          >
            1 miles
          </button>
          <button
            onClick={() => setRadius(3)}
            className="bg-gray-500 transition cursor-pointer hover:bg-gray-400 font-bold leading-none px-4 py-4"
          >
            3 miles
          </button>
          <button
            onClick={() => setRadius(5)}
            className="bg-gray-500 transition cursor-pointer hover:bg-gray-400 font-bold leading-none px-4 py-4"
          >
            5 miles
          </button>
          <button
            onClick={() => setRadius(10)}
            className="rounded-r-full bg-gray-500 transition cursor-pointer hover:bg-gray-400 font-bold leading-none pl-4 pr-6 py-4"
          >
            10 miles
          </button>
        </div>
      </div>

      <button
        onClick={() => download()}
        className="absolute row bottom-4 right-4 z-[500] bg-blue-500 text-white px-6 py-4 leading-none font-bold shadow-xl hover:bg-blue-400 transition rounded-full"
      >
        Download {Object.keys(seen.current).length} leads
      </button>

      <div className="absolute row bottom-4 left-4 z-[500] leading-none shadow-xl rounded-full">
        <select
          className="bg-white appearance-none text-gray-800 border-gray-200 border p-4 rounded-full"
          value={search}
          onChange={(event) => {
            console.log('change search');
            seen.current = {};
            updateUrl({ search: event.target.value });
          }}
        >
          {placeTypes.map((type) =>
            type.startsWith('--') ? (
              <option key={type} disabled>
                {upperFirst(type.replace(/_/g, ' '))}
              </option>
            ) : (
              <option key={type} value={type}>
                {upperFirst(type.replace(/_/g, ' '))}
              </option>
            )
          )}
        </select>
        <div className="absolute right-4 top-1/2 -translate-y-1/2">
          <ChevronsUpDown size={14} />
        </div>
      </div>
    </div>
  );
}
